<template>
<div>
 <v-card>
  <div class="text-center">
   <v-progress-linear stream
                       color="blue-grey"
                      :active="progress" 
                      :indeterminate="progress" 
                      ></v-progress-linear>
   </div>
     <v-card-title>
      Shaw Wassery EDIT-2-PACKAGES
      <v-spacer />
      
    </v-card-title>
  <v-data-table 
    :headers="headers"
    :items="content"
    :items-per-page="17"
    class="elevation-1">

    <template v-slot:[`pageText`]="props">
      ITEMS {{ props.pageStart }} - {{ props.pageStop }} OF {{ props.itemsLength }} // Edit this
      // ^this is what you need
    </template>

    <template v-slot:footer>
      <v-card class="ma-4"> 
          <v-card-text class="green lighten-4">
              Kliek op die klein ronde dingetjie om data te verander en die 
              snaakse + teken onder regs om iets by te voeg.
          </v-card-text>
      </v-card>
    </template>
    <template v-slot:[`footer.page-text`]>
       <v-btn icon @click="allocate()"> 
           <v-icon >mdi-table-plus</v-icon>
        </v-btn>

    </template>
    <template v-slot:[`item.description`]="{ item }">
        <div v-if="item.description.length > 40">    {{ item.description.substring(0,40) }} ... </div>
        <div v-else>{{ item.description }}</div>
      </template>
    <template v-slot:[`item.priceperkg`]="{ item }">
        <div v-if="item.reps">
          {{ item.priceperkg }} 
        || 
          {{ (item.price / (item.reps * item.weight)).toFixed(1) }}
        </div>
        <div v-else>
         {{ item.priceperkg }} 
        || Err 
          </div>

      </template>

    <template v-slot:[`item.sortorder`]="{ item }">
      {{ item.sortorder }}
       <v-btn icon @click="editCard( item.packageid )"> 
           <v-icon small>mdi-database-edit</v-icon>
        </v-btn>
       <v-btn icon @click="deleteCard( item.packageid )"> 
           <v-icon small>mdi-database-delete</v-icon>
        </v-btn>
      </template>
  </v-data-table>
 </v-card>


 <!-- DIALOG WINDOWS --------------------->
 <v-dialog v-model="editDialog">
  <v-card>
    <v-card-title>
      <span class="headline">Edit User</span>
    </v-card-title>
    <v-card-text>
     <v-container grid-list-md>
      <v-layout wrap>       
       <v-flex xs12 sm6 md4>
       <b>Some tips for Mike & Zimi.</b>
       <li>If you make sortorder 0, then the package will not be shown at all.</li>
       <li>If you make reps 0, dan sien mens nie die priceperkg nie.</li>
       <li>Jy kan al die nodige detail in die beskrywing intik.</li>
        </v-flex>
        <v-flex xs12 sm6 md4>
      <li>As jy iets in package insit - verskyn dit op hover - iets soos Special bv.</li>
      <li>As jy alles zero maak, verskyn net die beskrywing</li>
      <li>Jy kan dus al die nodige detail in die beskrywing intik.</li>
        </v-flex>
         </v-layout>
      </v-container>
         
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.packagename" label="PackageName"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.groupname" label="GroupName"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.service" label="Service"></v-text-field>
          </v-flex>
          <!--v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.price" 
                          label="Price" 
                          hint="" />
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.priceperkg" label="PricePerKG" hint="Make it zero to hide PricePerKg from site."></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-slider v-model="edit.weight"
                    label="Weight in KG (zero to hide from site)"
                    thumb-color="green"
                    thumb-label="always"
            />
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.period" label="Period"></v-text-field>
          </v-flex-->
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.sortorder" label="SortOrder"
                         hint="Make it Zero to hide complete package from site."></v-text-field>
          </v-flex>
          <!--v-flex xs12 sm6 md4>
          <v-slider v-model="edit.reps"
                    label="Repititions"
                    thumb-color="green"
                    thumb-label="always"
                    tip="Make it zero to hide reps from site."
          /></v-flex-->       
          <v-flex xs6 sm12 md6>
            <v-textarea v-model="edit.description" label="Description"></v-textarea>
          </v-flex>
          <v-flex xs4 sm10 md4 ripple class="wordbreak myitem text-center">
            <v-card class="ma-2 pa-2" color="red">Example/Voorbeeld
            <div v-html="edit.description" />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
      <v-spacer />
      <v-btn @click="editDialog=false" color="blue darken-1" text>Cancel</v-btn>
      <v-btn @click="saveData()" color="blue darken-1" text>Save</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
 </v-dialog>
</div>
</template>
<script>
import { zmlConfig } from '@/api/constants.js';
import { zmlFetch } from '@/api/zmlFetch.js';
import { zmlLog } from '@/api/zmlLog.js';
  export default {
    data: () => ({
        editDialog:false,
        edit: {packagename: 'new', description:' longer ', groupname: 'Walk-in'},
        editMode: null,
        progress:false,
        search: '',
        headers: [
          { text: 'Id', value: 'packageid' },
          { text: 'Name', value: 'packagename' },
          { text: 'Group', value: 'groupname' },
          { text: 'Service', value: 'service' },
          //{ text: 'Price', value: 'price' },
          //{ text: 'Price/KG', value: 'priceperkg' },
          //{ text: 'Period', value: 'period' },
          //{ text: 'Reps', value: 'reps' },
          //{ text: 'Weight', value: 'weight' },
          { text: 'Description', value: 'description' },
          { text: 'Order',  align: 'start',  value: 'sortorder' },
        ],
        content: [],
    }),
    methods: {
        allocate() { 
            this.edit = {packagename: 'package', description:' longer ', sortorder: 1};
            this.editDialog = true;
            this.editMode = 'add';
        },
        editCard(id) {
            let idx = this.content.findIndex(ele => ele.packageid == id);
            if (idx == -1) {
                alert(id + ' not found');
                return;
            }
            this.edit = this.content[idx];
            zmlConfig.cl(idx, this.edit);
            this.editDialog = true;
            this.editMode = 'update';
        },
        lowerOrder(id) {
           if (id < 2) {
               alert('Order is low enough');
               return;
           }
           let ts = {};
           ts.task = 'PlainSql';                  
           ts.sql = 'update was_package set sortorder = sortorder-1 where packageid = ' + id;
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        increaseOrder(id) {
           let ts = {};
           ts.task = 'PlainSql';                  
           ts.sql = 'update was_package set sortorder = sortorder+1 where packageid = ' + id;
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        saveData() {
           let ts = {};
           ts.data = this.edit;
           ts.mode = this.editMode;
           if (this.editMode == 'add')  {
              ts.task = 'insertPackage';
           } else {
              ts.task = 'updatePackage';
           }
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        afterUpdate(response) {
            zmlConfig.cl('AfterUpdate:',response);
            this.editDialog = false;
            this.loadData();
            zmlLog({task:"dolog",user:"None", pagename:"EditPackage", logdata: this.edit});
        },
        showData(response) {
            zmlConfig.cl('content=' , response);
            this.progress = false;
            if (response == '') {
              alert('no data received');
            } else {
              this.content = response;
            }
        },
        loadData() {
           let ts = {};
           //ts.sql = 'select * from was_package where sortorder != 0 order by sortorder, packagename';
           ts.sql = 'select * from was_package order by packagename , sortorder';
           ts.task = 'PlainSql';
           zmlConfig.cl(ts);
           zmlFetch(ts, this.showData);
        }
    },
    mounted: function () {
        zmlConfig.cl('Mount:Edit-2-package');
        this.loadData();
    }
  }
</script>


<style scoped>
.myitem {
    font-size: 14px;
    color: #222;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.wordbreak {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}