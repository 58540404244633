<template>
<div>
 <v-card>
  <div class="text-center">
   <v-progress-linear stream
                       color="blue-grey"
                      :active="progress" 
                      :indeterminate="progress" 
                      ></v-progress-linear>
   </div>
     <v-card-title>
      Shaw Wassery EDIT-1
      <v-spacer></v-spacer>
    </v-card-title>
  <v-data-table 
    :headers="headers"
    :items="content"
    :items-per-page="7"
    class="elevation-1"
    single-expand>
    <template v-slot:[`pageText`]="props">
      ITEMS {{ props.pageStart }} - {{ props.pageStop }} OF {{ props.itemsLength }} // Edit this
      // ^this is what you need
    </template>

    <template v-slot:footer>
      <v-card class="ma-4"> 
          <v-card-text class="green lighten-4">
              Kliek op die klein ronde dingetjie om data te verander, die op en af pyl om te skuif, en die 
              snaakse + teken onder regs om iets by te voeg.
          </v-card-text>
      </v-card>
    </template>
    <template v-slot:[`footer.page-text`]>
       <v-btn icon @click="allocate()"> 
           <v-icon >mdi-table-plus</v-icon>
        </v-btn>

    </template>
    <template v-slot:[`item.description`]="{ item }">
        <div v-if="item.description.length > 40">    {{ item.description.substring(0,40) }} ... </div>
        <div v-else>{{ item.description }}</div>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ etypes[item.type] }} 
      </template>

    <template v-slot:[`item.wasorder`]="{ item }">
      {{ item.wasorder}} 
       <v-btn x-small icon @click="editCard( item.wasid )"> 
           <v-icon x-small>mdi-database-edit</v-icon>
        </v-btn>
       <v-btn x-small icon @click="increaseOrder( item.wasid )"> 
           <v-icon x-small>mdi-plus</v-icon>
        </v-btn>        
       <v-btn x-small icon @click="lowerOrder( item.wasid )"> 
           <v-icon x-small>mdi-minus</v-icon>
        </v-btn>                
      </template>
  </v-data-table>
 </v-card>


 <!-- DIALOG WINDOWS --------------------->
 <v-dialog v-model="editDialog">
  <v-card>
    <v-card-title>
      <span class="headline">Edit User</span>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.title" label="Title"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.wasorder" label="Order"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field v-model="edit.type" label="Type"></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-textarea v-model="edit.description" label="Description"></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
      <v-spacer />
      <v-btn @click="editDialog=false" color="blue darken-1" text>Cancel</v-btn>
      <v-btn @click="saveData()" color="blue darken-1" text>Save</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
 </v-dialog>
</div>
</template>
<!--
description: "Laundry."
title: "Looking for a laundry service in Bloemfontein?"
type: "2"
wasid: "2"
wasorder: "2"
-->
<script>
import { zmlConfig } from '@/api/constants.js';
import { zmlFetch } from '@/api/zmlFetch.js';
import { zmlLog } from '@/api/zmlLog.js';
  export default {
    data: () => ({
        editDialog:false,
        edit: {title: 'new', description:' longer ', wasorder: 5, type: 2},
        editMode: null,
        progress:false,
        search: '',
        etypes:["zero","title","block","expand","button"],
        headers: [
          { text: 'Id', value: 'wasid' },
          { text: 'Type', value: 'type' },
          { text: 'Title', value: 'title' },
          { text: 'Description', value: 'description' },
          { text: 'Order',  align: 'start',  value: 'wasorder' },
        ],
        content: [],
    }),
    methods: {
        allocate() { 
            this.edit = {title: 'new', description:' longer ', wasorder: 5, type: 2};
            this.editDialog = true;
            this.editMode = 'add';
        },
        editCard(id) {
            let idx = this.content.findIndex(ele => ele.wasid == id);
            if (idx == -1) {
                alert(id + ' not found');
                return;
            }
            this.edit = this.content[idx];
            zmlConfig.cl(idx, this.edit);
            this.editDialog = true;
            this.editMode = 'update';
        },
        lowerOrder(id) {
           if (id < 2) {
               alert('Order is low enough');
               return;
           }
           let ts = {};
           ts.task = 'PlainSql';                  
           ts.sql = 'update was_cards set wasorder = wasorder-1 where wasid = ' + id;
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        increaseOrder(id) {
           let ts = {};
           ts.task = 'PlainSql';                  
           ts.sql = 'update was_cards set wasorder = wasorder+1 where wasid = ' + id;
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        saveData() {
           let ts = {};
           ts.data = this.edit;
           ts.mode = this.editMode;
           if (this.editMode == 'add')  {
              ts.task = 'insertCard';
           } else {
              ts.task = 'updateCard';
           }
           this.progress = true;
           zmlFetch(ts, this.afterUpdate);   
        },
        afterUpdate(response) {
            zmlConfig.cl('AfterUpdate:',response);
            this.editDialog = false;
            this.loadData();
            zmlLog({task:"dolog",user:"None", pagename:"EditWas", logdata: this.edit});
        },
        showData(response) {
            zmlConfig.cl('content=' , response);
            this.progress = false;
            this.content = response;
        },
        loadData() {
           let ts = {};
           ts.sql = 'select * from was_cards  order by wasorder';
           ts.task = 'PlainSql';
           zmlConfig.cl(ts);
           zmlFetch(ts, this.showData);
        }
    },
    mounted: function () {
        zmlConfig.cl('Mount:Edit-1-Was');
        this.loadData();
    }
  }
</script>