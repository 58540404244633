<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card color="primary"> Primary Color </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card color="secondary"> Secondary Color </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card color="accent"> Accent Color </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card color="#a5300f"> Other </v-card>
      </v-col>
      <v-col cols="12">
        <v-color-picker
        label="primary color"
        v-model="color"
        elevation="15"
       @input="changeColor"
        ></v-color-picker>
      </v-col>
    </v-row>
    </div>
</template>

<script>

export default {
    name:"EditColor",
    components: {},
    data: () => ({
//      getZml: getters.getState({ object: "gZml" }),
      types: ['hex', 'hexa', 'rgba', 'hsla', 'hsva'],
      type: 'hex',
      hex: '#FF00FF',
      hexa: '#FF00FFFF',
      rgba: { r: 255, g: 0, b: 255, a: 1 },
      hsla: { h: 300, s: 1, l: 0.5, a: 1 },
      hsva: { h: 300, s: 1, v: 1, a: 1 },      
     }),    
    methods:{
        changeColor() {
            this.type = 'hex'
            console.log('change color to: ', this.showColor)
            this.$vuetify.theme.themes.light.primary = this.showColor
        }
    },
    computed:{
      color: {
        get () {
          return this[this.type]
        },
        set (v) {
          this[this.type] = v
        },
      },
      showColor () {
        if (typeof this.color === 'string') return this.color
        console.log('incoming color = ', this.color)
        return JSON.stringify(Object.keys(this.color).reduce((ocolor, key) => {
          ocolor[key] = Number(this.color[key].toFixed(2))
          return ocolor
        }, {}), null, 2)
      },       
    },        
}
</script>
