<template>
<div>
 <v-card>
  <div class="text-center">
      Shaw Wassery EDIT Data
  
   </div>
     <v-card-title>
       <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
     <edit_package />
     <edit_was />
     <edit-color />
     </v-card-text>   
 </v-card>

</div>
</template>
<script>
import { zmlConfig } from '@/api/constants.js';
import editWas from '@/views/EditWas.vue';
import editPackage from '@/views/EditPackage.vue';
import EditColor from '@/views/EditColor.vue';
  export default {
      components: {edit_package:editPackage, edit_was:editWas, EditColor} ,
    data: () => ({
    }),
    methods: {
    },
    mounted: function () {
        zmlConfig.cl('Mount:Edit');
    }
  }
</script>